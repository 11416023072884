@mixin label-group($bg) {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: var(--6, 6px);
  background: $bg;
  color: var(--222222, #222);
  /* 16M */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */

  white-space: nowrap;
  transition: all 0.3s;
  user-select: none;
}
@mixin label-item($bg, $op) {
  width: 16px;
  height: 4px;

  border-radius: 1px;
  opacity: $op;
  background: $bg;
  transition: all 0.3s;
  cursor: pointer;
}
.screen2 {
  display: flex;
  gap: 30px;
  margin: 346px auto 0;
  padding: 0 100px;

  .title {
    position: absolute;
    left: 35px;
    white-space: nowrap;

    color: var(--222222, #222);
    text-align: center;
    /* 大标题 */
    font-family: Smiley Sans, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; /* 133.333% */

    transform: translate(50%, 0);
    opacity: 1;
    top: -196px;

    animation: title-scroll 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation-timeline: scroll(root y);
  }
  .module {
    //height: calc(100vh - 56px);
    display: flex;
    //align-items: center;
    transition: all 0.3s;
  }
  .module-content {
    position: relative;
    margin: 0 auto;
    min-height: 520px;
  }
  .item-label {
    position: absolute;
    top: -66px;
    display: flex;
    gap: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .item-content {
    flex: 1;
    .item-title {
      color: var(--222222, #222);
      /* 24Bd */
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 150% */
      margin-bottom: 10px;
    }
    .item-text {
      color: var(--222222, #222);
      text-align: justify;
      /* 16R */
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  .item-cover {
    flex: 1;
    height: fit-content;
    position: sticky;
    top: calc(256px + 6.72vh);
    //top: calc(50vh + 56px);
    //transform: translateY(-50%);
    &::before {
      content: '';
      display: block;
      padding-top: calc((9 / 16) * 100%);
    }

    &::after {
      content: '';
      position: absolute;
      top: -1000px;
      left: -1000px;
      display: block;
      width: 1703.484px;
      height: 1218.333px;
      transform: rotate(16.21deg);
      flex-shrink: 0;
      border-radius: 1703.484px;
      opacity: 0.1;
      background: var(--47-b-2-ff, #47b2ff);
      filter: blur(250px);
      pointer-events: none;
    }
    img {
      border-radius: 6px;
    }
  }
  .label-group {
    @include label-group(transparent);
  }

  .label-group-check {
    @include label-group(rgba(71, 178, 255, 0.3));
  }
  .label-item-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
    .label-item {
      @include label-item(#222, 0.1);
    }
    .label-item-check {
      @include label-item(#47b2ff, 1);
      & + .label-item {
        @include label-item(#222, 0.2);
      }
    }
  }
}

@keyframes title-scroll {
  0% {
    transform: translate(50%, 0);
    opacity: 1;
    position: absolute;
    top: -196px;
    left: 50vw;
  }
  15% {
    transform: translate(50%, 0);
    opacity: 1;
    position: absolute;
    top: -196px;
    left: 50vw;
  }
  15% {
    transform: translate(-50%, 0);
    opacity: 1;
    position: absolute;
    top: -196px;
    left: 50%;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
    position: absolute;
    top: -196px;
    left: 50%;
  }
}

@media (min-width: 1600px) {
  .screen2 {
    padding: 0 160px;
    max-width: 1280px;
  }
}

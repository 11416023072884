.page {
  width: 100%;
  padding-top: 56px;
  min-height: 100vh;
}
.bg {
  height: calc(100vh - 56px);
  z-index: -1;
}
.screen1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    padding: 0 0 20px 0;
    margin: 0;
    color: var(--ffffff, var(--white, #fff));
    font-family: Smiley Sans, sans-serif;
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; /* 100% */
  }
  .sub-title {
    color: var(--ffffff, var(--white, #fff));
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 100% */
  }
}
.screen3 {
  margin: 150px auto 0;
  padding: 0 100px;

  .title {
    color: var(--222222, #222);
    text-align: center;
    /* 大标题 */
    font-family: Smiley Sans, sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 80px; /* 133.333% */
  }
  .icon-group {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;
    margin: 50px auto 150px;
    .icon {
      position: relative;
      width: calc((100% - 60px) / 4);
      &::before {
        content: '';
        display: block;
        padding-top: calc((88 / 235) * 100%);
      }
    }
  }
}

@media (min-width: 1600px) {
  .screen3 {
    padding: 0 160px;
    max-width: 1280px;
  }
}
